import { Component } from '@angular/core';

export interface Card {
  name: string;
  video: string;
  image: string;
  play: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  public cards: Array<Card> = [
    {
      name: 'Mamá',
      video: 'assets/videos/irene.mp4',
      image: 'assets/images/irene.jpg',
      play: false
    },

    {
      name: 'Sofia',
      video: 'assets/videos/sofia.mp4',
      image: 'assets/images/sofia.jpg',
      play: false
    },

    {
      name: 'Gabriel',
      video: 'assets/videos/gabriel.mp4',
      image: 'assets/images/gabriel.jpg',
      play: false
    },

    {
      name: 'Pamela',
      video: 'assets/videos/pamela.mp4',
      image: 'assets/images/pamela.jpg',
      play: false
    },

    {
      name: 'Mario',
      video: 'assets/videos/mario.mp4',
      image: 'assets/images/mario.jpg',
      play: false
    },

    {
      name: 'Camilo',
      video: 'assets/videos/camilo.mp4',
      image: 'assets/images/camilo.jpg',
      play: false
    }
  ];
}
